import React, { useEffect, useState, FC, useContext, useRef } from 'react';
import NextImage from 'next/image';
import { AlgoliaProduct, AlgoliaSwatch } from '@Types/product/Product';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { useStorage } from 'helpers/hooks/useStorage';
import { LayoutContext } from 'frontastic/provider/layout';
import { LocaleContext } from 'frontastic/provider/locale';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { amplienceImageLoader } from '../../../../helpers/imageLoaders/amplienceImageLoader';
import { ProductCardTag } from '../product-details/product-card-tag/index';
import styles from './product-card.module.scss';

export interface ProductCardProps {
  product: AlgoliaProduct;
  sizes?: string;
  priority?: boolean;
  gridLayout?: number;
  addSelectItemEvent?: () => void;
}

const ProductCard: FC<ProductCardProps> = ({
  product,
  sizes = '100vw',
  priority = false,
  gridLayout = 1,
  addSelectItemEvent,
}) => {
  const productCartAltImage = 'https://cdn.media.amplience.net/i/lisroyan/TP21FITG002';
  const [activeSwatch, setActiveSwatch] = useState<AlgoliaSwatch>(
    product.swatches?.find((swatch) => swatch.TP_SWATCH_IS_ACTIVE) || null,
  );
  const [imageSource1, setImageSource1] = useState(
    product.images?.[`${activeSwatch?.TP_COLOUR_SWATCH}-1`] ?? productCartAltImage,
  );
  const [imageSource2, setImageSource2] = useState(
    product.images?.[`${activeSwatch?.TP_COLOUR_SWATCH}-2`] ?? productCartAltImage,
  );
  const { setItem } = useStorage();
  const layout = useContext(LayoutContext);
  const [numberOfColourSwatchesToDisplay, setNumberOfColourSwatchesToDisplay] = useState(7);
  const productCardRef = useRef(null);
  const locale = useContext(LocaleContext);
  useEffect(() => {
    const componentWidth = productCardRef.current.offsetWidth;
    const colourSwatchWidth = layout.isMobile ? 30 : 22;
    const paddingBetween = 16;
    const spaceForNumber = layout.isMobile ? 30 : 22;
    const coloursToShow = Math.floor((componentWidth - spaceForNumber) / (colourSwatchWidth + paddingBetween));
    setNumberOfColourSwatchesToDisplay(coloursToShow);
  }, [productCardRef?.current?.offsetWidth]);

  const changeDisplayedImage = (event) => {
    const colourSwatch = event.currentTarget.value;
    const selectedSwatch = product.swatches.find((swatch) => colourSwatch === swatch.TP_COLOUR);

    setActiveSwatch(selectedSwatch);
    setImageSource1(product.images?.[`${selectedSwatch?.TP_COLOUR_SWATCH}-1`] ?? productCartAltImage);
    setImageSource2(product.images?.[`${selectedSwatch?.TP_COLOUR_SWATCH}-2`] ?? productCartAltImage);
  };

  const handleImageError = () => {
    setImageSource1(productCartAltImage);
    setImageSource2(productCartAltImage);
  };

  function plpImageLoader({ src, width }) {
    return amplienceImageLoader('PLP', src, width);
  }

  const handleOnClick = () => {
    setItem('lastVisitedProductId', product.productId, 'session');
    addSelectItemEvent();
  };
  return (
    <div style={{ width: '100%' }} id={product.productId} data-testid="product-card" ref={productCardRef}>
      <NextLink href={activeSwatch?.TP_URL || product.url || ''} as={activeSwatch?.TP_URL || product.url || ''}>
        <span
          className={classNames(styles['product-result_image-link'], styles['js-gtm-click'])}
          onClick={handleOnClick}
          data-testid={product.productId}
        >
          <ProductCardTag productCardTag={product.productCardTag} />
          <div className={styles['figure']}>
            <div className={styles.wrapper}>
              {priority ? (
                <NextImage
                  priority
                  {...{ fetchpriority: 'high' }}
                  sizes={sizes}
                  layout="fill"
                  loader={plpImageLoader}
                  onError={handleImageError}
                  src={imageSource1}
                  alt="Product image"
                  data-testid={`product-card-${product.productId}`}
                />
              ) : (
                <NextImage
                  sizes={sizes}
                  layout="fill"
                  loader={plpImageLoader}
                  onError={handleImageError}
                  src={imageSource1}
                  alt="Product image"
                  data-testid={`product-card-${product.productId}`}
                />
              )}
            </div>
            <div className={styles.wrapper}>
              <NextImage
                sizes={sizes}
                layout="fill"
                loader={plpImageLoader}
                onError={handleImageError}
                src={imageSource2}
                alt="Product image"
              />
            </div>
          </div>
          <div className={styles['product-result_info']}>
            <div className={styles['product-result_details']}>
              <h2 data-testid="product-name">{activeSwatch?.NAME || product.name}</h2>
            </div>
            <div className={styles['product-result_price_wrapper']}>
              <div
                className={
                  activeSwatch?.TP_PRICE?.[1]?.discountedPrice
                    ? styles['product-result_price_old']
                    : styles['product-result_price']
                }
                data-testid="product-price"
              >
                {CurrencyHelpers.formatForCurrency(
                  {
                    centAmount: activeSwatch?.TP_PRICE ? activeSwatch.TP_PRICE?.[1].price : product.price?.[1].price,
                    currencyCode: activeSwatch?.TP_PRICE ? activeSwatch.TP_PRICE?.[0] : product.price?.[0],
                    fractionDigits: 2,
                  },
                  0,
                )}
              </div>
              {activeSwatch?.TP_PRICE?.[1].discountedPrice && (
                <div data-testid="product-discounted-price" className={styles['product-result_price_discounted']}>
                  {CurrencyHelpers.formatForCurrency(
                    {
                      centAmount: activeSwatch.TP_PRICE?.[1].discountedPrice,
                      currencyCode: activeSwatch.TP_PRICE?.[0],
                      fractionDigits: 2,
                    },
                    0,
                  )}
                </div>
              )}
            </div>
          </div>
        </span>
      </NextLink>
      <div className={styles['product-result_colour_swatch_container']}>
        <div data-testid="product-colour-buttons" className={styles['product-result_options']}>
          {product.swatches &&
            product.swatches.length > 0 &&
            product.swatches.map((swatch, index) => {
              return (
                index < numberOfColourSwatchesToDisplay && (
                  <button
                    onClick={changeDisplayedImage}
                    className={classNames(styles['product-result_options_colourButton'], {
                      [styles['product-result_options_colourButton__selected']]:
                        swatch?.TP_COLOUR_SWATCH === activeSwatch?.TP_COLOUR_SWATCH,
                    })}
                    value={swatch.TP_COLOUR}
                    key={`${product.productId}-${swatch.TP_COLOUR_SWATCH}-${index}`}
                    aria-label={`Product colour swatch ${swatch.TP_COLOUR}`}
                  >
                    <label
                      style={{
                        backgroundImage: `url(${swatch.TP_SWATCH_IMAGE})`,
                        backgroundPosition: 'center',
                      }}
                    ></label>
                  </button>
                )
              );
            })}
          {product.swatches && product.swatches.length > numberOfColourSwatchesToDisplay && (
            <NextLink href={product.url} as={product.url}>
              <span className={styles['product-result_options_moreColours_label']}>
                +{product.swatches.length - numberOfColourSwatchesToDisplay}
              </span>
            </NextLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
