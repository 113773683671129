import { AlgoliaProduct } from '@Types/product/Product';
import { fetchApiHub } from '../../../frontastic/lib/fetch-api-hub';

type QueryParams = {
  ids?: string[];
  productIds?: string[];
  limit?: number;
  category?: string;
  query?: string;
  cursor?: string;
  [key: `facets${string}`]: string;
  [key: `sortAttributes${string}`]: string;
  offset?: number;
  length?: number;
};

export const getProductsByQuery = async (queryParams: QueryParams): Promise<AlgoliaProduct[]> => {
  let url = '/action/algolia/search';
  if (queryParams && Object.keys(queryParams).length) {
    url += '?';

    Object.entries(queryParams).forEach((queryParam, index) => {
      const [key, value] = queryParam;

      if (value !== undefined && value !== '') {
        if (index !== 0) url += '&';

        url += `${key}=`;

        if (typeof value === 'string' || typeof value === 'number') {
          url += value;
        } else {
          url += value.join(',');
        }
      }
    });
  }

  const data = await fetchApiHub(url);
  return data.results.items;
};
