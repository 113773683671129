import styles from './product-card-tag.module.scss';
export interface ProductCardProps {
  productCardTag?: string;
}

const productCardTagColourMap = new Map<string, string>([
  ['Most Popular', '#625F5F'],
  ['Featured', '#625F5F'],
  ['Available as Set', '#625F5F'],
  ['New In', '#5B0F0E'],
  ['Sale', '#5B0F0E'],
  ['Back in Stock', '#5B0F0E'],
  ['Archer St', '#C57372'],
  ['Jermyn St', '#C57372'],
]);

export const ProductCardTag: React.FC<ProductCardProps> = ({ productCardTag }: ProductCardProps) => {
  return (
    <>
      {productCardTag && productCardTag !== '' && (
        <div
          className={styles.productCardTag}
          style={{ backgroundColor: productCardTagColourMap.get(productCardTag) || '#C57372' }}
          data-testid="product-card-tag"
        >
          <span className={styles.productCardLabel} data-testid="product-card-tag-label">
            {productCardTag}
          </span>
        </div>
      )}
    </>
  );
};
